import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Button, Row, Media, Col, Card } from 'react-bootstrap'
import ArticleCard from "./ArticleCard"
import CommonFlex from './commonFlex'
import { Link } from "gatsby"
// import ArticleMediaCard from './ArticleMediaCard' // 暂时不用了
import { bdstatistics, apiURL, mqStatistics, GetQueryString } from '../components/census'

import './../assets/css/aritcle.css'
import './../assets/css/aritclesDetail.css'

// 行业动态页中使用
// pages/artices已经不用这个了
const StaticQueryCommon = props => {
  const tag = GetQueryString('tag')

  React.useEffect(() => {
    if(typeof window !== "undefined") {
      // 跳转到指定位置
      let common_media = document.getElementsByClassName('common-flex');
      if(tag) {
        for(let i=0; i<common_media.length;i++) {
          let name = common_media[i].getAttribute('name')
          if(name == tag) {
            window.scrollTo({
              top: common_media[i].offsetTop -150
            })
          }
        }
      }
    }

  //   fetch(`${apiURL}/application-scenarios?_sort=updated_at:DESC`, {
  //     headers: { 'content-type': 'application/json' },
  //   })
  //     .then((res) => {
  //       if (res.status == 200) {
  //         return res.json()
  //       }
  //     })
  //     .then(json => {
  //       setData([ ...json, { bannerTitle: '更多案例！', homeImage: { url: '' }, indImage: [ { id: Math.random(), url: require('./../images/WechatIMG163.png') } ], id: Math.random(), tag: 'mome' } ])
  //     })
  //     .catch(err => console.log(err))

  }, [tag])
  // let newData = props?.staticType == 'tg' ? data.filter(val=>val?.sort).sort((a,b) => a.sort - b.sort) : data.sort((a,b) => b.sort - a.sort)
  // console.log(newData, '=========')
  return (
    <div>
      <StaticQuery query={graphql`
        query Articles {
          allStrapiArticles(sort: {fields: published_at, order: DESC}) {
            nodes {
              id
              slug
              title
              image {
                childImageSharp {
                  fixed(width: 1600, height: 800) {
                    base64
                  }
                }
              },
              tags
              strapiId
              category {
                name
                id
              },
              description,
              flag,
              recomment,
              content,
              articlesTag
            }
          }
        }
        `}
        render={data => {
          let a = data.allStrapiArticles.nodes.filter(item => (!item.flag && item.tags)).map(item => {
            if(item.tags) { return item.tags }
          })
          let b = a && a.length && a.filter((item, index, a)=> {
            return a.indexOf(item, 0) === index
          })
          let c = b.map(item => {
            let obj = {
              title: item,
              data: []
            }
            data.allStrapiArticles.nodes.filter(item => !item.flag).forEach(val => {
              if(val.tags == item) {
                obj.data.push(val)
              }
            })
            return obj
          })
          let industryDynamics = data.allStrapiArticles.nodes.filter(item => {
            if(props?.currentIndustry == 'dy') {
              return item.recomment && item.articlesTag == '答疑'
            } else {
              return item.recomment
            }
          }).map(item => item)
          const string = '查看更多>>'
          return props?.type == 'industryDynamics' ? (
            <>
              {
                industryDynamics.map((item, index) => {
                  return (<Link to={item.articlesTag == '公开课' ? `${item.content}` : `/article/${item.slug}`} target={item.articlesTag == '公开课' ? `_black` : '_self' } className="industry-link" style={{ textDecoration: 'none' }}>
                    <Media className="industry-media-box" key={index}>
                    <div className="industry-img-box">
                      {
                        item?.image?.childImageSharp?.fixed?.base64 ? <img style={{ width: '23.75rem', height: '10rem' }} src={item.image.childImageSharp.fixed.base64} alt={item.descImg} /> : null
                      }
                    </div>
                    <Media.Body>
                      <div className="industry-title">{item.title}</div>
                      <div className="industry-desc">{item.description}</div>
                      <div className="more">{string}</div>
                    </Media.Body>
                  </Media></Link>)
                })
              }
            </>
          ) : props?.type != 'articles' ? (
            <>
              <div className="articles-btn-group">
                {
                  b.map((item, index) => {
                    return <Button onClick={() => { 
                      props.changeCommom(index, item)
                    }} className={index === props?.active ? 'article-btn active' : 'article-btn'} variant="primary">{item}</Button>
                  })
                }
              </div>
              {
                c.map(item => {
                  return (
                    <>
                      <CommonFlex title={item.title} name={item.title}></CommonFlex>
                      <Row xs={1}>
                        {
                          item.data.map(article => {
                            return <ArticleCard article={article} />
                          })
                        }
                      </Row>
                    </>
                  )
                })
              }
            </>
          ) : null
        }}
      />
      {/* <ArticleMediaCard {...props} /> */}
    </div>

  )
}

export default StaticQueryCommon
