import React from "react"  
import { Link } from "gatsby"
import { Col, Card } from 'react-bootstrap'
import './../assets/css/aritcle.css'
import { techURL } from './census'
import { useStaticQuery, graphql } from "gatsby"

const ArticleCard = ({ article }) => { 
  // const [ data, setData ] = React.useState()
  // React.useEffect(()=>{
  //   fetch(`${apiURL}/articles`, {
  //     headers: { 'content-type': 'application/json' },
  //   })
  //     .then((res) => {
  //       if (res.status == 200) {
  //         return res.json()
  //       }
  //     })
  //     .then(json => {
  //       setData(json)
  //     })
  //     .catch(err => console.log(err))
  // }, [ JSON.stringify(articlesData) ])

  const articles = useStaticQuery(
    graphql`
    query{
      allStrapiArticles (sort: {fields: created_at, order: DESC}) {
        edges {
          node {
            title,
            slug,
            recomment,
            articlesTag,
            created_at,
            content,
            image {
              childImageSharp {
                fixed(width: 1600, height: 800) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            },
            description
          }
        }
      }
    }`
  )

  const articlesData = articles?.allStrapiArticles?.edges
  const data = articlesData?.length ? articlesData.map(item => item?.node) : []

  return (
    <Col>
      <Link to={`/article/${article.slug}`} className="article-link">
        <Card style={{ }} className="article-card">
          {
            article && data && data.length ? data.map(item => {
              if(item.id === article.strapiId) {
                return item?.image?.url && <img src={`${techURL}${item.image.url}`} key={item.id} alt="" />
              }
            }) : 
            article?.image?.childImageSharp?.fixed?.base64 ? 
              <img src={article.image.childImageSharp.fixed.base64} />
              // <Img fixed={article.image.childImageSharp.fixed}  alt="" />
            : null
          }
          <Card.Body>
            <Card.Title>{article.title}</Card.Title>
            <Card.Text>{article.description}</Card.Text>
            {/* <Card.Text>分类: {article.category.name}</Card.Text> */}
          </Card.Body>
        </Card>
      </Link>
    </Col>
  )
}

export default ArticleCard  